<template>
  <KTCard ref="preview" v-bind:example="true" id="dashboard-box">
    <template v-slot:title v-if="showTitle">
      <h3 class="card-title">
        <slot name="buttons"></slot>
        <slot name="title"></slot>
      </h3>
    </template>
    <template v-slot:toolbar>
      <slot name="toolbar"></slot>
    </template>
    <template v-slot:body>
      <slot name="preview"></slot>
    </template>
  </KTCard>
</template>
<script>
import KTCard from "@/view/content/Card.vue";
export default {
  name: "DashboardBox",
  props: {
    title: String,
    back: Boolean,
    backComponentName: String,
    showTitle: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      tabIndex: 0,
      isOpen: false
    };
  },
  components: {
    KTCard
  },
  mounted() {
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('[data-toggle="tab"]');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      // set current active tab
      event.target.classList.add("active");
      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));
    },
    backButton () {
      if (this.backComponentName) {
        this.$router.push({name: this.backComponentName})
      } else {
        this.$router.go(-1)
      }
      this.$emit('back')
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';
.back-button-color {
  color: $primary-gray !important
}
#dashboard-box{
  border-radius: 5px;
  background-color: #fff;
}
</style>
